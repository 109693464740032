<template>
  <div id="select-profile-container">
    <div id="profiles-header">
    <h2>You’re all set. Choose the profile to join {{guestData.host.name}} playspace.</h2>
    <div v-if="error" class="error-msg">{{error}}</div>
    <br/>
    <div id="profile-list">
        <div v-for="(profile, index) in profileList" :key="index" class="d-inline-block align-top">
            <div class="profile-item">
                <div :id="profile.id" :isActive="activeIndex === index" :class="{'avatar-selected':index == activeIndex}" class="avatar-container avatar-lg avatar" @click="setSwitchProfile(index,profile)">
                    <img 
                    class="avatar-img"
                    :src="profile.profilePhoto" 
                    width="120"
                    height="120"
                    />
                </div> 
                <div class="profile-name truncate">{{profile.name}}</div>
            </div> 
        </div>
    </div>
    <br/><br/>
   </div> 
   <div class="d-inline-block align-top"> 
      <b-button pill variant="primary" class="d-inline-block" @click="goHome()">
          <div class="home-icon ps-icons"></div>
          {{$t('global.gohome')}}
      </b-button> 
   </div>
   <div class="btn-right d-inline-block align-top">    
      <b-button pill variant="primary" class="d-inline-block" @click="joinSwitchProfile()">
          <div class="join-icon ps-icons"></div>
          {{$t('global.letsgo')}}
      </b-button>  
    </div>  
  </div>  
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "Selectprofile", 
  computed: {
    ...mapState(['profiles']),     
    ...mapState(['userData']),   
    ...mapState(['guestData']),      
  }, 
  data() {
    return {
        error: null,
        selectedProfile: null,
        activeIndex: null,
        profileList: this.$store.getters.profileList
    }
  },
  watch: {
    'profiles': {
      handler (newVal) {
        if (newVal) { 
          this.profileList = this.$store.getters.profileList;
        }
      },
      immediate: true 
    }    
  },    
  methods: {
    setSwitchProfile(index,profile){  
        this.tracking({
            type: 'event',
            category: 'join-flow',
            action: 'selectprofile:profile',
        }); 
      //TODO add class avatar-selected
      //let elname = "#"+el;
      if (this.activeIndex === index) {
          this.activeIndex = null;
      } else {
          this.activeIndex = index;
      }
      //document.getElementById(el).classList.remove("avatar-selected");
      //document.getElementById(el).classList.add("avatar-selected");
      this.selectedProfile = profile;
      //this.switchProfile(profile)           
    },
    goHome(){
        this.tracking({
            type: 'event',
            category: 'join-flow',
            action: 'selectprofile:gohome',
        });       
      this.$router.push('/home');
      this.$store.commit('setInvite', null);
    },
    joinSwitchProfile() {
        this.tracking({
            type: 'event',
            category: 'join-flow',
            action: 'selectprofile:letsgo',
        });          
        if(this.selectedProfile != null) {
            this.switchProfile(this.selectedProfile);
            //TODO add check for room ID?
            this.$router.push('/join/'+this.guestData.id+'/lobby');

        } else {
            this.error = this.$t('errors.noprofileset');
        }
    }
  },
  mounted() {
    this.tracking({
      type: 'page',
      title: '/join/selectprofile',
    });  
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#select-profile-container {
    .btn-right {
      position: absolute;
      right: 70px;
    }
    #profiles-header{
      width: 500px;
      margin: 0 auto;      
    }
    #profile-list {
        width: 500px;
        margin: 0 auto;
        .avatar-container{
            margin: 0 15px 15px 0;
        }
        .profile-name {
            width: 140px;
            text-align: center;
            font-size: 11px;
            margin: 0 15px 15px 0;
        }
        .truncate {
            width: 140px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }         
    }

}
</style>