<template>
    <div id="unsupported-modal">
        <Smallmodal
        :modalShow="showModal"
        @closeSmallModal="closeModal()"
        >
        <div id="unsupported-modal-content" v-if="message == 'nomobile'">
            <h2>{{$t('nomobile.heading')}}</h2>
            <p>{{$t('nomobile.body')}}</p>
            <br/><br/>
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
        </div>        
        <div id="unsupported-modal-content" v-if="message == 'device'">
            <h2>{{$t('unsupported.heading')}}</h2>
            <p>{{$t('unsupported.body')}}</p>
            <br/><br/>
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
        </div>
        <div id="unsupported-modal-content" v-if="message == 'direction'">
            <h2>{{$t('rotatescreen.heading')}}</h2>
            <p>{{$t('rotatescreen.body')}}</p>
            <br/><br/>
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
        </div> 
        <div id="unsupported-modal-content" v-if="message == 'webcam'">
            <h2>{{$t('webcam.heading')}}</h2>
            <p>{{$t('webcam.body')}}</p>
            <br/><br/>
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
        </div>                  
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'
export default {
    name: "devicesupport", 
    components: {
        Smallmodal
    },
    props:{
        showModal: Boolean,
        message: String
    },
    watch: {
        'showPlayerleft': {
            handler (newVal) {
                if (newVal && this.showPlayerleft == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'activity:nav',
                        action: 'popup:view',
                        label: 'devicesupport'
                    });
                }
            },
            immediate: true 
        }
    },      
    computed: {
        ...mapState(['userData']), 
    },
    data: function() {
        return {
            error: null,
        }   
    },
    methods: {
        closeModal() {
            this.$emit('closeUnsupported');
            this.tracking({
                type: 'event',
                category: 'activity:nav',
                action: 'popup:close',
                label: 'devicesupport'
            });            
        },
        moreInfo() {
            this.$emit('closeUnsupported');
            this.tracking({
                type: 'event',
                category: 'devicesupport',
                action: 'join:moreinfo',
            });  
            window.location.href = 'https://www.playspaces.co/faq'; 
        }
    },
    mounted(){
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#unsupported-modal-content {
    padding: 40px 0 30px 0;

}


</style>