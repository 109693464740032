<template>
  <div>
      <unsupported
      :showModal="show.modal"
      :message="supportMessage"
      @closeUnsupported="closeUnsupported()"
      />  
      <div id="invite-details" v-if="show.welcome == true">
          <div class="heading">
            <h2>
              {{this.signUpCodeData.firstname}}{{$t('join.betajoin.heading')}}
            </h2>
          </div>
          <div class="button-group" v-if="userData == null">
            <b-button pill variant="primary" @click="sendSignUp()">
                <div class="signup-icon ps-icons"></div>
                {{$t('global.signup')}}
            </b-button>
            <b-button pill variant="primary" @click="sendLogin()">
              <div class="login-icon ps-icons"></div>
              {{$t('global.login')}}
            </b-button> 
          </div>
          <div class="button-group" v-else>
            <b-button pill variant="primary" @click="goHome()">
              <div class="home-icon ps-icons"></div>
              {{$t('global.gohome')}}
            </b-button>             
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
          </div>          
        </div>
        <div v-else>
          <div class="heading">
            <h2>
              {{message}}
            </h2>
          </div>
          <div class="button-group" v-if="userData == null">
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>  
          </div>
          <div class="button-group" v-else>
            <b-button pill variant="primary" @click="goHome()">
              <div class="home-icon ps-icons"></div>
              {{$t('global.gohome')}}
            </b-button>             
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>             
          </div>
              
        </div>  
  </div>  
</template>

<script>
import { mapState } from 'vuex';
import unsupported from '@/components/modals/devicesupport.vue';
export default {
  name: "Beta", 
  components: { unsupported },
  computed: {
    ...mapState(['userData']),
    ...mapState(['signUpCodeData']),  
  }, 
  data() {
    return {
      invite: {
        id: this.$route.params.beta_id,
        timezone: 'America/New_York',

      },
      show: {
        modal: false,
        welcome: false
      },
      supportMessage: "device",
      error: null,
      message: null
    }
  },
  methods: {
    sendSignUp() {
      this.$router.push('/agechecker');  
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'beta:signup',
      });       
    },
    sendLogin() {
      this.$store.commit('setSignUp', null);
      this.$router.push('/login');
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'beta:login',
      });        
    },
    goHome() {
      this.$store.commit('setSignUp', null);
      this.$router.push('/home'); 
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'beta:gohome',
      });           
    },
    moreInfo() {
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'beta:moreinfo',
      });  
      window.location.href = 'https://www.playspaces.co/faq';     
    },
    closeUnsupported() {
      this.show.modal = false;
    },
    openUnsupported() {
      this.show.modal = true;
    },    
    checkSupport(){
      if(!this.deviceSupport()) {
        this.show.modal = true;
        this.supportMessage = "device";
      } else if(!this.isLandscape()){
        this.show.modal = true;
        this.supportMessage = "direction";        
      } else if(!this.noWebcam()){
        this.show.modal = true;
        this.supportMessage = "webcam";              
      } 
    },
    async loadBetaData(codeId) {
      try {
        await this.$store.dispatch('signUpCode', codeId);
        if(this.signUpCodeData.email != null) {
          if(this.signUpCodeData.used === false) {
            this.show.welcome = true;
          } else {
            this.message = this.$t('join.betajoin.codeused');
            this.$store.commit('setSignUp', null); 
          }    
        } else {
          this.message = this.$t('join.betajoin.notfound');
          this.$store.commit('setSignUp', null);           
        }
      } catch(error){
        this.message = error;
        this.show.welcome = false; 
        this.$store.commit('setSignUp', null);       
      }
    }
  },
  mounted() {

    this.loadBetaData(this.$route.params.beta_id);
   
    this.tracking({
      type: 'page',
      title: '/join/beta',
    }); 
    this.checkSupport();   
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#join-container {
  position: relative;
  width: 100%;
  height: 100%;
  #join-content {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .heading {
      margin: 80px auto 30px;
      width: 300px;
      h2 {
        color: $ps-white;
      }
    }
    .button-group {
      .btn-primary {
        margin:0 15px 30px 0;
      }
    }
    .aliens {
      img {
        margin: 0 -60px 0 0 ;
      }
    }
  }
}

</style>