import { render, staticRenderFns } from "./selectprofile.vue?vue&type=template&id=98131d40&scoped=true&"
import script from "./selectprofile.vue?vue&type=script&lang=js&"
export * from "./selectprofile.vue?vue&type=script&lang=js&"
import style0 from "./selectprofile.vue?vue&type=style&index=0&id=98131d40&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98131d40",
  null
  
)

export default component.exports