<template>
  <div>
      <unsupported
      :showModal="show.modal"
      :message="supportMessage"
      @closeUnsupported="closeUnsupported()"
      />  
      <div id="invite-details" v-if="showInvite == true">
          <div class="heading">
            <h2 v-if="loading == true">Loading</h2>
            <h2 v-else>
              {{$t('join.welcomeguest.firstline')}}<br/> 
              {{$t('join.welcomeguest.secondline')}}{{invite.host.name}}, 
              {{invite.displayDate}}<br/>  
              {{$t('global.at')}} 
              {{invite.displayStartTime}} 
              {{$t('global.to')}}
              {{invite.displayEndTime}} 
              .
            </h2>
          </div>
          <div class="button-group" v-if="userData == null">
            <b-button pill variant="primary" @click="sendSignUp()">
                <div class="signup-icon ps-icons"></div>
                {{$t('global.signup')}}
            </b-button>
            <b-button pill variant="primary" @click="sendLogin()">
              <div class="login-icon ps-icons"></div>
              {{$t('global.login')}}
            </b-button> 
          </div>
          <div class="button-group" v-else>
            <b-button pill variant="primary" @click="continueLobby()">
              <div class="join-icon ps-icons"></div>
              {{$t('global.continue')}}
            </b-button>             
          </div>
        </div>
        <div v-else>
          <div class="heading">
          <h2>{{message}}</h2>
          </div>
          <div class="button-group" v-if="userData == null">
            <b-button pill variant="primary" @click="sendSignUp()">
                <div class="signup-icon ps-icons"></div>
                {{$t('global.signup')}}
            </b-button>  
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>                 
          </div>     
          <div class="button-group" v-else>
            <b-button pill variant="primary" @click="goHome()">
              <div class="home-icon ps-icons"></div>
              {{$t('global.gohome')}}
            </b-button>  
            <b-button pill variant="primary" @click="moreInfo()">
                <div class="info-icon ps-icons"></div>
                {{$t('global.moreinfo')}}
            </b-button>                        
          </div>               
        </div>  

        
  </div>  
</template>

<script>
import { mapState } from 'vuex';
import unsupported from '@/components/modals/devicesupport.vue';
export default {
  name: "Join", 
  components: { unsupported },
  computed: {
    ...mapState(['userData']),
    ...mapState(['guestData']),  
  },
  watch: {
    'guestData': {
        handler (newVal) {
            let rightNow = new Date();
            if (newVal) {
              if (this.guestData != null) {
                this.loading = false;
                this.invite.startDateTime = new Date(this.guestData.startDateTime);
                this.invite.endDateTime = new Date(this.guestData.endDateTime); 
                let nextDay = new Date(this.invite.startDateTime);
                nextDay.setDate(nextDay.getDate() + 1);
                this.invite.displayDate = this.guestData.displayDate;
                this.invite.displayStartTime = this.guestData.displayStartTime;
                this.invite.displayEndTime = this.guestData.displayEndTime;
                this.invite.guest.name = this.guestData.guest.name;
                this.invite.guest.email = this.guestData.guest.email;
                this.invite.guest.inviteCode = this.guestData.guest.inviteCode;
                this.invite.host.name = this.guestData.host.name || "MISSING";
                this.invite.host.userId = this.guestData.host.userId || "MISSING";
                this.invite.timezone = this.guestData.timezone;
                this.invite.status = this.guestData.status;
                if(this.isDatePast(nextDay, rightNow)){
                  this.showInvite = false;
                  this.message = this.$t('join.expired.heading');
                  this.$store.commit('setInvite', null);
                } else {
                  if(this.userData != null) {
                    //console.log(this.userData.email + " " + this.invite.guest.email);
                    if((this.userData.email.toLowerCase() == this.invite.guest.email.toLowerCase()) || (this.userData.id == this.invite.host.userId) ){
                      this.showInvite = true;
                    } else {
                      this.showInvite = false;
                      this.message = "It looks like that invite is for someone else!"                  
                    }
                  } else {
                    this.showInvite = true;                  
                  }
                }          
              } else {
                this.showInvite = false;
                this.message = this.$t('join.unknown.heading'); 
                this.$store.commit('setInvite', null);         
              }
            }
        },
        immediate: true 
    }
  },      
  data() {
    return {
      invite: {
        id: this.$route.params.invite_id,
        timezone: 'America/New_York',
        status: null,
        diplayDateTime: null,
        startDateTime: null,
        endDateTime: null, 
        displayDate: null,
        displayStartTime: null,
        displayEndTime: null,      
        guest: {
          name: null,
          email: null,
          userId: null,
          profileId: null,
          inviteCode: null
        },
        host: {
          name: null,
          roomId: null,
        }
      },
      loading: true,
      show: {
        modal: false
      },
      supportMessage: "device",
      showInvite: false,
      error: null,
      message: "Loading"
    }
  },
  methods: {
    sendSignUp() {
      this.$router.push('/agechecker');  
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'join:signup',
      });       
    },
    sendLogin() {
      this.$router.push('/login');
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'join:login',
      });        
    },
    goHome() {
      this.$router.push('/home'); 
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'join:gohome',
      });           
    },
    moreInfo() {
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'join:moreinfo',
      });  
      //window.location.href = 'https://www.playspaces.co/faq';  
      window.open('https://www.playspaces.co/faq','_blank');     
    },
    continueLobby(){
      this.$router.push('/selectprofile/');
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'join:continue',
      });       
    },
    closeUnsupported() {
      this.show.modal = false;
    },
    openUnsupported() {
      this.show.modal = true;
    },    
    checkSupport(){
      if(!this.deviceSupport()) {
        this.show.modal = true;
        this.supportMessage = "device";
      } else if(this.isPhone()) {
        this.show.modal = true;
        this.supportMessage = "nomobile";        
      } else if(!this.isLandscape()) {
        this.show.modal = true;
        this.supportMessage = "direction";        
      } else if(!this.noWebcam()) {
        this.show.modal = true;
        this.supportMessage = "webcam";              
      }      
    },
    async loadInviteData(inviteId) {
      let rightNow = new Date();
      this.$store.dispatch('getInviteJoin',inviteId)
      .then(res => {
        if(res !== null) {
          //console.log(res);
          this.message = "Invite not found.";
          this.showInvite = false;
          this.$store.commit('setInvite', null);
        }
      });
    }
  },
  mounted() {
    this.loadInviteData(this.$route.params.invite_id);
    this.tracking({
      type: 'page',
      title: '/join',
    }); 
    //TODO move support checks to welcome or join layout
    this.checkSupport();   
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';

#join-container {
  position: relative;
  width: 100%;
  height: 100%;
  #join-content {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .heading {
      margin: 80px auto 30px;
      width: 300px;
      h2 {
        color: $ps-white;
      }
    }
    .button-group {
      .btn-primary {
        margin:0 15px 30px 0;
      }
    }
    .aliens {
      img {
        margin: 0 -60px 0 0 ;
      }
    }
  }
}

</style>