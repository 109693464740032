import { render, staticRenderFns } from "./lobby.vue?vue&type=template&id=5ddc4dce&scoped=true&"
import script from "./lobby.vue?vue&type=script&lang=js&"
export * from "./lobby.vue?vue&type=script&lang=js&"
import style0 from "./lobby.vue?vue&type=style&index=0&id=5ddc4dce&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ddc4dce",
  null
  
)

export default component.exports