<template>
  <div id="unsupported-container" >
    <div class="heading">
         <h2>{{$t('join.region.heading')}}</h2>   
    </div>  
    <div class="button-group">
        <b-button pill variant="primary" @click="subscribeLink()">
            <div class="email-icon ps-icons"></div>
            {{$t('global.subscribe')}}
        </b-button> 
    </div>    
  </div>  
</template>

<script>
// TODO rename this or reuse it?
export default {
  name: "Unsupported", 
  computed: {

  },   
  data() {
    return {
      error: null
    }
  },
  methods: {
    subscribeLink(){
      this.tracking({
        type: 'event',
        category: 'unsupported',
        action:  'subscribe:click'
      });      
      window.location.href = 'https://www.playspaces.co/internationalwaitlist';
    }
  },
  mounted() {
    this.tracking({
      type: 'page',
      title: '/region-unsupported',
    });
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';




</style>