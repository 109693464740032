<template>
  <div id="lobby-container">
    <Busy
    :showModal="show.busy"
    @closeModal="closeBusy()"
    />
    <Cancelled
    :showModal="show.cancelled"
    @returnHome="goHome()"
    @closeModal="closeCancelled()"
    />
    <div id="header">
      <h1>{{$t('join.waitingroom.heading')}}</h1> 
      <br/>
      <h2>
      {{invite.host.name}}'s Playspace<br/> 
      {{invite.displayDate}}<br/>
      {{invite.displayStartTime}} - {{invite.displayEndTime}} 
      </h2>
      <img src="/img/elements/join/lobby_aliens.svg" width="504" height="217"/>
    </div>  
    <br/><br/><br/>
   <div class="d-inline-block align-top"> 
      <b-button pill variant="primary" class="d-inline-block" @click="goHome()">
          <div class="home-icon ps-icons"></div>
          {{$t('global.gohome')}}
      </b-button> 
   </div>    
    <div class="btn-right d-inline-block align-top">    
      <b-button pill variant="primary" @click="continuetoPlayspace()" v-if="show.readyToEnter == true">
        <div class="confirm-icon ps-icons"></div>
        {{$t('global.continue')}}
      </b-button>  
      <b-button pill variant="primary" v-if="show.waiting == true">
        <div class="loading-icon-white ps-icons"></div>
        {{$t('global.waiting')}}
      </b-button>
      <b-button pill variant="secondary" v-if="show.loading == true">
        <div class="loading-icon ps-icons"></div>
        {{$t('global.loading')}}
      </b-button>                            
    </div>
 
  </div> 
</template>
<script>
import { mapState } from 'vuex'
import Busy from '@/components/modals/hostbusy.vue'
import Cancelled from '@/components/modals/cancelled.vue'
import Vue from 'vue';
import psPusher from '@/services/pusher';
export default {
  name: "Lobby", 
  components: {
    Busy,
    Cancelled
  },
  computed: {
    ...mapState(['currentRoom']), 
    ...mapState(['activeProfile']), 
    ...mapState(['guestData']), 
  }, 
  watch: {
    'currentRoom': {
        handler (newVal) {
          if (newVal) {
            if(this.currentRoom.open === true && this.host !== null) { //&& this.currentRoom.players.length < 2
              this.show.readyToEnter = true;
              this.show.waiting = false; 
            } else {
              this.show.readyToEnter = false;  
              this.show.waiting = true;            
            }
          }
        },
        immediate: true 
    },   
    'host': {
        handler (newVal) {   
          if (newVal) {
            console.log("HOST"+JSON.stringify(this.host));
          }
        },
        immediate: true 
    },           
  },     
  data() {
    return {
      inWaiting: null,
      hostReady: false,
      host: null,
      error: null,
      show: {
        busy: false,
        cancelled: false,
        loading: false,
        waiting: true,
        readyToEnter: false,
      },
      invite: {
        id: this.$route.params.invite_id,
        timezone: 'America/New_York',
        status: null,
        diplayDateTime: null,
        startDateTime: null,
        endDateTime: null, 
        displayDate: null,
        displayStartTime: null,
        displayEndTime: null,      
        guest: {
          name: null,
          email: null,
          userId: null,
          profileId: null,
          inviteCode: null
        },
        host: {
          name: null,
          roomId: null,
        }
      },      
    }
  },
  methods: {
    closeBusy() {
      this.show.busy = false;
    },
    closeCancelled() {
      this.show.cancelled = false;
    },    
    continuetoPlayspace(){
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'lobby:continue',
      });        
      this.show.readyToEnter = false;
      this.show.loading = true;
      this.exitWaitingRoom();
      this.$pusher.unsubscribe('presence-playspaces-lobby-' + this.currentRoom.id);
      this.$router.push('/play/space/' + this.currentRoom.id);  
    },
    checkWaiting(waiting){
      try {
        if(waiting === null) {
          this.joinWaitingRoom();
        } else {
          if(waiting.length <= 0) {
            this.joinWaitingRoom();          
          } else {
            if(waiting[0].userProfileId !== this.$store.getters.userProfileId) {
              // TODO check if there's an old waiting user that didn't get cleared out.
              // TODO check if they are waiting (reload/ refresh)
              this.$router.push('/playspace/error');
            }
          }
        }
      } catch (error) {
        this.$sentry.captureException(error);
      }
    },
    loadWaiting(members) {
      try {      
        /*if(members.length == 2) {
          // both here
          console.log("there are 2 in the lobby");
        } */             
        this.inWaiting = members;
        this.inWaiting.each(player => {
          if(player.info.userProfileId === this.currentRoom.userProfileId) {
              this.host = player.info;
              this.joinWaitingRoom();
          } 
        })
      } catch (error) {
        this.$sentry.captureException(error);
      }      
    },    
    joinWaitingRoom() {
      try {
        // Sends notification to host that guest is waiting
        let profile = {
            userProfileId: this.$store.getters.userProfileId,
            name: this.activeProfile.name,
            profilePhoto: this.activeProfile.profilePhoto,
            type: this.activeProfile.type,
            status: "waiting"
        };
        this.lobby.trigger('client-guest-waiting', profile);
      } catch (error) {
        this.$sentry.captureException(error);
      }  
    },
    async goHome(){
      this.tracking({
          type: 'event',
          category: 'join-flow',
          action: 'lobby:gohome',
      });       
      this.exitWaitingRoom();
      this.$pusher.unsubscribe('presence-playspaces-lobby-' + this.currentRoom.id);
      this.$router.push('/home');
    },    
    async exitWaitingRoom(){
      await this.$store.commit('setInvite', null);
      // resets the current room
      this.switchProfile(this.activeProfile);
    },
    async loadRoomData() {
      try {
        // Get full invite data from secure endpoint
        let invite = await this.$store.dispatch('getInvite', this.guestData.id)
        .catch(function(error) {
          this.logError(error);
          this.message = error;
        });
        if (invite != null) {
          this.invite.startDateTime = new Date(invite.startDateTime);
          this.invite.endDateTime = new Date(invite.endDateTime); 
          this.invite.displayDate = invite.displayDate;
          this.invite.displayStartTime = invite.displayStartTime;
          this.invite.displayEndTime = invite.displayEndTime;
          this.invite.host.name = invite.host.name || "MISSING";
          this.invite.host.roomId = invite.host.roomId;
          this.invite.timezone = invite.timezone;
          this.invite.status = invite.status;  
        } else {
          // TODO handle error if the data doesn't load
          this.error = "error"
        }    
        
        this.$store.dispatch('getRoom', invite.host.roomId)
        .then(async () => {
          await this.pusherConnect(this.invite.host.roomId);    
        });        

        // Subscribe to updates for the room in Firebase
        // Let's us know when it's open or closed
        this.$roomsCollection.doc(invite.host.roomId)
        .onSnapshot(async (doc) => {
            let room = doc.data();
            room.id = invite.host.roomId;
            await this.$store.commit('setCurrentRoom', room)
        });


      
        
      
    
      } catch (error) {
        this.$sentry.captureException(error);
      }  
    },
    async pusherConnect(roomId) {
        //this.$pusher.logToConsole = true;
        this.lobby = this.$pusher.subscribe('presence-playspaces-lobby-' + roomId);
        this.lobby.bind("pusher:member_added", (member) => {
            this.loadWaiting(this.lobby.members);
            //this.isHostReady();
            console.log(member.info);
        });
        this.lobby.bind("pusher:member_removed", (member) => {
            this.loadWaiting(this.lobby.members);
            //this.isHostReady();
            console.log(member.info);
        })        
        this.lobby.bind('client-host-busy', (data) => {
            this.show.busy = true;
        });
        this.lobby.bind('client-host-canncelled', (data) => {
            this.show.cancelled = true;
        }); 
        this.lobby.bind('client-host-room-open', (data) => {
            //this.continuetoPlayspace();
        });   
        this.lobby.bind("pusher:subscription_succeeded", (members) => {
          this.loadWaiting(this.lobby.members);
          this.joinWaitingRoom();
        });   
        this.lobby.bind("pusher:subscription_error", (error) => {
          //this.$sentry.captureException(error);
        });               
    },
    pusherUnbind() {
      this.lobby.unbind('client-host-canncelled');
      this.lobby.unbind('client-host-busy');
    }  
  },
  beforeCreate() {
    Vue.prototype.$pusher = new psPusher();
  },    
  mounted() {
    this.loadRoomData();   
    this.tracking({
      type: 'page',
      title: '/join/lobby',
    });     
  },
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/variables';
#lobby-container {
  .btn-right {
    position: absolute;
    right: 70px;
  }
  #header{
    width: 500px;
    margin: 0 auto; 
    text-align: center;     
  }
}

</style>