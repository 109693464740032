<template>
    <div id="lobby-modal">
        <Smallmodal
        :modalShow="showModal"
        @closeSmallModal="closeModal()"
        >
        <div id="lobby-modal-content">
            <div>
                <img src="/img/elements/join/onhold.svg" width="253" height="226" :alt="$t('inactive.stillplaying')" :title="$t('inactive.stillplaying')"/>                
                <br/><br/>
                <h2>{{$t('join.waitingroom.hostbusy')}}</h2>
                <br/><br/>
                <b-button pill variant="primary" @click="okay()" >
                    <div class="confirm-icon ps-icons"></div>
                    {{$t('global.okay')}}
                </b-button>  
            </div>                                                           
        </div>
        </Smallmodal>
    </div>    
</template>

<script>
import { mapState } from 'vuex'
import Smallmodal from '@/components/modals/smallmodal.vue'

export default {
    name: "hostbusy", 
    components: {
        Smallmodal
    },
    props:{
        showModal: Boolean,
    },
    watch: {
        'showModal': {
            handler (newVal) {
                if (newVal && this.showModal == true) { 
                    this.tracking({
                        type: 'event',
                        category: 'join-flow',
                        action: 'hostbusy',
                        label: 'popup:view'
                    });
                }
            },
            immediate: true 
        },      
    },      
    computed: {

    },
    data: function() {
        return {
            error: null,
        }   
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
            this.tracking({
                type: 'event',
                category: 'join-flow',
                action: 'hostbusy',
                label: 'popup:close'
            });            
        },
        okay() {
            this.$emit('closeModal');
            this.tracking({
                type: 'event',
                category: 'join-flow',
                action: 'hostbusy',
                label: 'popup:okay'
            });             
        }
    },
    mounted(){

    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables';
#lobby-modal-content {
}
</style>